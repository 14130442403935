<template>
  <div class="cooperation">
    <div class="wrap" v-if="windowWidth">
      <div class="introduce">
        <p class="cooperation-title">
          <span class="center">University Collaborations</span>
        </p>
        <p class="content">
          <span
            >Tencent ARC Lab has cooperated with many well-known domestic and foreign institutions</span
          >
        </p>
      </div>
      <div class="university_box">
        <div class="uniBox">
          <p class="img_box"><img src="../../../assets/img/uni1.png" /></p>
          <p class="name">Tsinghua University</p>
        </div>
        <div class="uniBox">
          <p class="img_box">
            <img src="../../../assets/img/beijing_logo.jpeg" />
          </p>
          <p class="name">Peking University</p>
        </div>
        <div class="uniBox">
          <p class="img_box">
            <img src="../../../assets/img/Science_logo.jpeg" />
          </p>
          <p class="name">University of Science and Technology of China</p>
        </div>
        <div class="uniBox">
          <p class="img_box"><img src="../../../assets/img/nus_logo.png" /></p>
          <p class="name">National University of Singapore</p>
        </div>
        <div class="uniBox">
          <p class="img_box"><img src="../../../assets/img/uni2.png" /></p>
          <p class="name">
            Institute of automation, Chinese Academy of Sciences
          </p>
        </div>
        <div class="uniBox">
          <p class="img_box"><img src="../../../assets/img/Academy.png" /></p>
          <p class="name">University of Chinese Academy of Sciences</p>
        </div>
        <div class="uniBox">
          <p class="img_box"><img src="../../../assets/img/uni7.png" /></p>
          <p class="name">University of Hong Kong</p>
        </div>
        <div class="uniBox">
          <p class="img_box">
            <img src="../../../assets/img/zhejiang_logo.png" />
          </p>
          <p class="name">Zhejiang University</p>
        </div>
        <div class="uniBox">
          <p class="img_box"><img src="../../../assets/img/uni3.png" /></p>
          <p class="name">Fudan University</p>
        </div>
        <div class="uniBox">
          <p class="img_box"><img src="../../../assets/img/uni8.png" /></p>
          <p class="name">Huazhong University of Science and Technology</p>
        </div>
        <div class="uniBox">
          <p class="img_box"><img src="../../../assets/img/uni_a1.png" /></p>
          <p class="name">The Hong Kong Polytechnic University</p>
        </div>
        <div class="uniBox">
          <p class="img_box"><img src="../../../assets/img/uni6.png" /></p>
          <p class="name">Texas A&M University</p>
        </div>
        <div class="uniBox">
          <p class="img_box">
            <img src="../../../assets/img/nankai_logo.jpeg" />
          </p>
          <p class="name">Nankai University</p>
        </div>
        <div class="uniBox">
          <p class="img_box"><img src="../../../assets/img/uni4.png" /></p>
          <p class="name">Xiamen University</p>
        </div>
        <div class="uniBox">
          <p class="img_box">
            <img src="../../../assets/img/dalian_logo.jpg" />
          </p>
          <p class="name">Dalian University of Technology</p>
        </div>
        <div class="uniBox">
          <p class="img_box"><img src="../../../assets/img/uni_a2.png" /></p>
          <p class="name">NYU Shanghai</p>
        </div>
        <div class="uniBox">
          <p class="img_box"><img src="../../../assets/img/siat_logo.jpg" /></p>
          <p class="name">
            Shenzhen Institute of Advanced Technology,Chinese Academy of
            Sciences
          </p>
        </div>
      </div>
      <div class="sponsor">
        <p class="title">
          <span class="center">Sponsorship</span>
        </p>
        <p class="content">
          <span
            >Tencent ARC Lab is the sponsor of several top domestic and
            international conferences including CVPR, ACM MM, ICLR, NLPCC, PRCV,
            VALSE, etc.</span
          >
        </p>
        <div class="img_box">
          <div>
            <img src="../../../assets/img/sponsor1.png" alt="" />
          </div>
          <div>
            <p class="name">NLPCC</p>
            <p class="time">2021</p>
          </div>
          <div>
            <img src="../../../assets/img/sponsor4.png" alt="" />
          </div>
          <div>
            <p class="name">VALSE</p>
            <p class="time">2021</p>
          </div>
        </div>
        <div class="img_box">
          <div>
            <img src="../../../assets/img/CVPR2022.png" alt="" />
          </div>
          <div>
            <img src="../../../assets/img/ICLR-logo.png" alt="" />
          </div>
          <div>
            <p class="name">VALSE</p>
            <p class="time">2022</p>
          </div>
        </div>
      </div>
    </div>
    <div class="wraps" v-else>
      <div class="introduces">
        <p class="cooperation-titles">
          <span class="center">University collaborations</span>
        </p>
        <p class="content">
          <span
            >Tencent ARC Lab has cooperated with many well-known domestic and foreign institutions</span
          >
        </p>
      </div>
      <div class="university_boxs">
        <div class="uni_box">
          <div class="uniBox">
            <p class="img_box"><img src="../../../assets/img/uni1.png" /></p>
            <p class="name">Tsinghua University</p>
          </div>
          <div class="uniBox">
            <p class="img_box">
              <img src="../../../assets/img/beijing_logo.jpeg" />
            </p>
            <p class="name">Peking University</p>
          </div>
        </div>
        <div class="uni_box">
          <div class="uniBox">
            <p class="img_box">
              <img src="../../../assets/img/Science_logo.jpeg" />
            </p>
            <p class="name">University of Science and Technology of China</p>
          </div>
          <div class="uniBox">
            <p class="img_box">
              <img src="../../../assets/img/nus_logo.png" />
            </p>
            <p class="name">National University of Singapore</p>
          </div>
        </div>
        <div class="uni_box">
          <div class="uniBox">
            <p class="img_box"><img src="../../../assets/img/uni2.png" /></p>
            <p class="name">
              Institute of automation, Chinese Academy of Sciences
            </p>
          </div>
          <div class="uniBox">
            <p class="img_box">
              <img src="../../../assets/img/Academy.png" />
            </p>
            <p class="name">University of Chinese Academy of Sciences</p>
          </div>
        </div>
        <div class="uni_box">
          <div class="uniBox">
            <p class="img_box"><img src="../../../assets/img/uni7.png" /></p>
            <p class="name">University of Hong Kong</p>
          </div>
          <div class="uniBox">
            <p class="img_box">
              <img src="../../../assets/img/zhejiang_logo.png" />
            </p>
            <p class="name">Zhejiang University</p>
          </div>
        </div>
        <div class="uni_box">
          <div class="uniBox">
            <p class="img_box"><img src="../../../assets/img/uni3.png" /></p>
            <p class="name">Fudan University</p>
          </div>
          <div class="uniBox">
            <p class="img_box"><img src="../../../assets/img/uni8.png" /></p>
            <p class="name">Huazhong University of Science and Technology</p>
          </div>
        </div>
        <div class="uni_box">
          <div class="uniBox">
            <p class="img_box"><img src="../../../assets/img/uni_a1.png" /></p>
            <p class="name">The Hong Kong Polytechnic University</p>
          </div>
          <div class="uniBox">
            <p class="img_box"><img src="../../../assets/img/uni6.png" /></p>
            <p class="name">Texas A&M University</p>
          </div>
        </div>
        <div class="uni_box">
          <div class="uniBox">
            <p class="img_box">
              <img src="../../../assets/img/nankai_logo.jpeg" />
            </p>
            <p class="name">Nankai University</p>
          </div>
          <div class="uniBox">
            <p class="img_box"><img src="../../../assets/img/uni4.png" /></p>
            <p class="name">Xiamen University</p>
          </div>
        </div>
        <div class="uni_box">
          <div class="uniBox">
            <p class="img_box">
              <img src="../../../assets/img/dalian_logo.jpg" />
            </p>
            <p class="name">Dalian University of Technology</p>
          </div>
          <div class="uniBox">
            <p class="img_box"><img src="../../../assets/img/uni_a2.png" /></p>
            <p class="name">NYU Shanghai</p>
          </div>
        </div>
        <div class="uni_box">
          <div class="uniBox">
            <p class="img_box">
              <img src="../../../assets/img/siat_logo.jpg" />
            </p>
            <p class="name">
              Shenzhen Institute of Advanced Technology,Chinese Academy of
              Sciences
            </p>
          </div>
        </div>
      </div>
      <div class="sponsors">
        <p class="title">
          <span class="center">Sponsorship</span>
        </p>
        <p class="content">
          <span
            >Tencent ARC Lab is the sponsor of several top domestic and
            international conferences including CVPR, ACM MM, ICLR, NLPCC, PRCV,
            VALSE, etc.</span
          >
        </p>
        <div class="img_boxs">
          <div>
            <img src="../../../assets/img/sponsor1.png" alt="" />
          </div>
          <div>
            <p class="name">NLPCC</p>
            <p class="time">2021</p>
          </div>
          <div>
            <img src="../../../assets/img/sponsor4.png" alt="" />
          </div>
          <div>
            <p class="name">VALSE</p>
            <p class="time">2021</p>
          </div>
          <div>
            <img src="../../../assets/img/CVPR2022.png" alt="" />
          </div>
          <div>
            <img src="../../../assets/img/ICLR-logo.png" alt="" />
          </div>
          <div>
            <p class="name">VALSE</p>
            <p class="time">2022</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import bus from "../../../assets/js/eventBus";
export default {
  created() {
    window.addEventListener("resize", this.getWidth);
    this.getWidth();
  },
  destroyed() {
    window.removeEventListener("resize", this.getWidth);
  },
  mounted() {
    bus.$emit("sendBybus", this.bannerHeight);
    bus.$emit("textBybus", this.text);
  },
  data() {
    return {
      bannerHeight: 400,
      text: "Collaborations",
      windowWidth: true,
    };
  },
  methods: {
    // 获取页面宽度
    getWidth() {
      if (window.innerWidth > 1032) {
        this.windowWidth = true;
      } else {
        this.windowWidth = false;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.cooperation {
  width: 100%;
  font-size: 0.16rem;
  .wrap {
    max-width: 1200px;
    margin: 0 auto;
    .introduce {
      margin-bottom: 0.4rem;
      margin-top: 0.6rem;
      .cooperation-title {
        height: 0.5rem;
        line-height: 0.5rem;
        text-align: center;
        margin-bottom: 0.15rem;
        .center {
          display: inline-block;
          font-family: PingFangSC-Semibold, sans-serif;
          font-size: 0.36rem;
          color: #222;
        }
      }
      .content {
        text-align: center;
        span {
          display: inline-block;
          height: 0.32rem;
          font-family: PingFangSC-Regular, sans-serif;
          font-size: 0.16rem;
          color: #666666;
          line-height: 0.32rem;
          text-align: center;
        }
      }
    }
    .university_box {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: left;
      justify-items: center;
      .uniBox {
        text-align: center;
        margin-bottom: 0.6rem;
        width: 20%;
        .img_box {
          display: inline-block;
          width: 1.2rem;
          height: 1.2rem;
          img {
            width: 100%;
            height: 1.2rem;
            object-fit: contain;
          }
        }
        .name {
          font-family: PingFangSC-Regular;
          font-size: 0.2rem;
          color: #222222;
          text-align: center;
          margin-top: 0.2rem;
        }
      }
    }
    .sponsor {
      margin-top: 0.8rem;
      margin-bottom: 0.6rem;
      .title {
        height: 0.5rem;
        line-height: 0.5rem;
        text-align: center;
        margin-bottom: 0.15rem;
        .center {
          display: inline-block;
          font-family: PingFangSC-Semibold, sans-serif;
          font-size: 0.36rem;
          color: #222;
        }
      }
      .content {
        text-align: center;
        span {
          width: 9.2rem;
          display: inline-block;
          font-family: PingFangSC-Regular, sans-serif;
          font-size: 0.16rem;
          color: #666666;
          line-height: 0.32rem;
          text-align: center;
        }
      }
      .img_box {
        margin-top: 0.4rem;
        display: flex;
        justify-content: left;
        div {
          width: 2.2rem;
          height: 1.3rem;
          margin-left: 0.5rem;
          background: #ffffff;
          box-shadow: 0 0 0.08rem 0 rgba(0, 0, 0, 0.07);
          -webkit-box-shadow: 0 0 0.08rem 0 rgba(0, 0, 0, 0.07);
          -moz-box-shadow: 0 0 0.08rem 0 rgba(0, 0, 0, 0.07);
          -o-box-shadow: 0 0 0.08rem 0 rgba(0, 0, 0, 0.07);
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
          p {
            color: #0153a1;
            font-size: 0.26rem;
            text-align: center;
            font-weight: 600;
            letter-spacing: 0.02rem;
            font-family: PingFangSC-Semibold, sans-serif;
          }
          .name {
            margin-top: 0.25rem;
          }
        }
        div:first-child {
          margin-left: 0.85rem;
        }
      }
    }
  }
  .wraps {
    max-width: 750px;
    margin: 0 auto;
    .introduces {
      margin-top: 1rem;
      margin-bottom: 0.6rem;
      .cooperation-titles {
        height: 0.67rem;
        margin-bottom: 0.22rem;
        text-align: center;
        .center {
          display: inline-block;
          font-family: PingFangSC-Semibold;
          font-size: 0.48rem;
          color: #222;
        }
      }
      .content {
        margin: 0 auto;
        text-align: center;
        span {
          display: inline-block;
          width: 5.76rem;
          line-height: 0.52rem;
          height: 4.3%;
          font-family: PingFangSC-Regular;
          font-size: 0.28rem;
          color: #666666;
          text-align: center;
          margin-left: 0.3rem;
        }
      }
    }
    .university_boxs {
      width: 100%;
      .uni_box {
        margin-bottom: 0.6rem;
        overflow: hidden;
        .uniBox {
          float: left;
          text-align: center;
          width: 50%;
          .img_box {
            display: inline-block;
            width: 2rem;
            height: 2rem;
            img {
              width: 100%;
              height: 2rem;
              object-fit: contain;
            }
          }
          .name {
            font-family: PingFangSC-Regular;
            font-size: 0.32rem;
            color: #222222;
            text-align: center;
            margin-top: 0.4rem;
          }
        }
      }
    }
    .sponsors {
      margin-top: 1rem;
      .title {
        height: 0.67rem;
        line-height: 0.67rem;
        text-align: center;
        margin-bottom: 0.22rem;
        .center {
          display: inline-block;
          font-family: PingFangSC-Semibold, sans-serif;
          font-size: 0.48rem;
          color: #222;
        }
      }
      .content {
        text-align: center;
        span {
          display: inline-block;
          margin: 0 auto;
          width: 5.76rem;
          line-height: 0.52rem;
          height: 4.3%;
          font-family: PingFangSC-Regular;
          font-size: 0.28rem;
          color: #666666;
          text-align: center;
        }
      }
      .img_boxs {
        margin: 0.895rem 1.2rem 0.74rem;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        div {
          width: 2.2rem;
          height: 1.3rem;
          background: #ffffff;
          margin-bottom: 0.3rem;
          box-shadow: 0 0 0.08rem 0 rgba(0, 0, 0, 0.07);
          -webkit-box-shadow: 0 0 0.08rem 0 rgba(0, 0, 0, 0.07);
          -moz-box-shadow: 0 0 0.08rem 0 rgba(0, 0, 0, 0.07);
          -o-box-shadow: 0 0 0.08rem 0 rgba(0, 0, 0, 0.07);
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
          p {
            color: #0153a1;
            font-size: 0.26rem;
            text-align: center;
            font-weight: 600;
            letter-spacing: 0.02rem;
            font-family: PingFangSC-Semibold, sans-serif;
          }
          .name {
            margin-top: 0.28rem;
          }
        }
      }
    }
  }
}
</style>